<template>
  <div>
    <v-snackbar class="white--text text-center" top v-model="snackbar" :timeout="3000" :color="notificationType">
      {{ notificationText }}
    </v-snackbar>
    <v-row>
      <v-col cols="12" md="7">
        <v-card outlined class="pa-5" v-if="stops.length">
          <div v-for="(item, i) in stops" :key="item.id">
            <v-row no-gutters justify="space-between" align="center">
              <v-col cols="12" md="3">
                <div class="text-capitalize">
                  {{ item.stop_number + ' ' }}
                  <v-icon v-if="item.stop_travel_by_id" class="mx-2">
                    {{ transportations.find(trans => trans.id === item.stop_travel_by_id).slug }}
                  </v-icon>
                  {{ item.location_label }}
                  <br/>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                            class="caption text-truncate d-block mx-3">{{ item.formatted_address }}</span>
                    </template>
                    <span>{{ item.formatted_address }}</span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col v-if="item.home === 0" cols="12" md="3" class="caption my-2">
                <v-icon left>mdi-calendar</v-icon>
                {{ new Date(item.stop_from_date).toDateString() }}
                {{ new Date(item.stop_from_date).toTimeString().substring(0, 5) }}
              </v-col>
              <v-col v-if="item.stop_number > 1" cols="12" md="3" class="caption my-2">
                <v-icon left>mdi-calendar</v-icon>
                {{ new Date(item.stop_to_date).toDateString() }}
                {{ new Date(item.stop_to_date).toTimeString().substring(0, 5) }}
              </v-col>
              <v-col cols="12" md="3">
                <v-btn text small color="primary" @click="stop = item; stopIndex = i; actionType = 'edit'; updateDialog = true;">
                  <v-icon>mdi-square-edit-outline</v-icon>
                  Edit
                </v-btn>
                <v-btn text small color="primary" v-if="i !== 0" @click="deleteStop(item.id)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                  Delete
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="my-3" v-if="i + 1 < stops.length"></v-divider>
          </div>
        </v-card>

        <!-- stop form -->
        <v-card v-if="showStopsForm || goHome" class="pa-3" elevation="1">
          <stopForm
            :stopsCount="stops.length"
            :stops="stops"
            :tripId="id"
            :transportations="transportations"
            :stays="stays"
            :home="goHome"
            v-if="showStopsForm || goHome"
            @closeForm="noHome"
            @setStops="setStops"
            @error="showError"
          />
        </v-card>

        <v-btn v-if="!showStopsForm && !wentHome" @click="showStopsForm = true" x-large text color="primary">
          <v-icon left>mdi-plus-circle</v-icon>
          add stop
        </v-btn>
        <v-btn v-if="stops.length >= 2 && !goHome && !wentHome" @click="goHome = true; wentHome = true" x-large text
               color="primary">
          <v-icon left>mdi-plus-circle</v-icon>
          Go home
        </v-btn>
      </v-col>

      <!-- map -->
      <v-col cols="12" md="5">
        <GmapMap
          :center='center'
          ref="gmap"
          :zoom='8'
          style='width:100%;  height: 400px;'
        >
          <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            @click="center = m.position"
          />
          <GmapPolyline v-bind:path.sync="path" v-bind:options="{ strokeColor:'#FF8F95'}"/>
        </GmapMap>
      </v-col>
    </v-row>

    <!-- update stop dialog-->
    <v-dialog v-model="updateDialog" max-width="90%" min-width="50%">
      <v-card class="pa-5">
        <stopForm
          v-if="updateDialog"
          :type="actionType"
          :stopsCount="stops.length"
          :editingStop="stop"
          :tripId="id"
          :transportations="transportations"
          :stays="stays"
          :stops="stops"
          :home="stopIndex === 0"
          @closeForm="noHome($event)"
          @setStops="setStops"
          @error="showError"
        />
      </v-card>
    </v-dialog>

    <v-row v-if="stops.length" no-gutters justify="center" class="mt-10">
      <v-btn class="mx-5" color="white primary--text" x-large
             @click="$router.push({ path: `/plan-trip/plan-view/${id}` })">view trip announcement
      </v-btn>
      <v-btn class="mx-5 px-15" color="primary white--text" x-large elevation="0"
             @click="$router.push({ path: '/plan-trip' })">save
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import planner from '../../web_services/planner'
import stopForm from '@/components/plan_trip/stopForm'
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'GoogleMap',
  props: ['id'],
  components: { stopForm },
  data () {
    return {
      markers: [],
      goHome: false,
      wentHome: false,
      path: [],
      center: {
        lat: 45.508,
        lng: -73.587
      },
      transportations: [],
      stays: [],
      showStops: false,
      showStopsForm: false,
      stop: null,
      stops: [],
      addStopLoad: false,
      snackbar: false,
      notificationType: '',
      notificationText: '',
      updateDialog: false,
      actionType: 'add',
      stopIndex: 0
    }
  },
  computed: {
    google: gmapApi,
    sequence () {
      if (!this.stops.length) {
        this.setFirstStop()
        return [1]
      } else if (this.stops.length === 1) {
        return [2]
      } else {
        var list = []
        for (var i = 2; i <= this.stops.length + 1; i++) {
          list.push(i)
        }
        return list
      }
    }
  },
  methods: {
    noHome (event) {
      if (event === 'done') {
        this.updateDialog = false
        this.showStopsForm = false
      } else if (event === 'home') {
        this.updateDialog = false
        this.showStopsForm = false
        this.wentHome = true
        this.goHome = false
      } else if (event === 'update') {
        this.showStopsForm = false
        this.updateDialog = false
      } else {
        this.updateDialog = false
        this.wentHome = false
        this.showStopsForm = false
        this.goHome = false
      }
    },
    setStops (stops) {
      this.stops = stops
      this.setLocations(this.stops, 'set')
    },
    deleteStop (id) {
      planner.deletePlanStop({ trip_plan_id: this.id }, id).then(response => {
        this.stops.splice(this.stops.findIndex(a => a.id === id), 1)
        this.setLocations(this.stops, 'set')
      }).catch(err => {
        this.showNotification('error', err.response.data.message)
      })
    },
    setLocations (stops, action) {
      if (action === 'set') {
        this.markers = []
        this.path = []
      }
      stops.forEach(stop => {
        const marker = {
          lat: stop.stop_To_lat,
          lng: stop.stop_To_lng
        }
        this.markers.push({ position: marker })
        this.path.push(marker)
        this.center = marker
      })
      setTimeout(() => {
        this.$refs.gmap.$mapPromise.then((map) => {
          const bounds = new this.google.maps.LatLngBounds()
          for (const m of this.markers) {
            bounds.extend(m.position)
          }
          map.fitBounds(bounds)
        })
      }, 500)
    },
    geolocate () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      })
    },
    showError (type, msg) {
      let message = ''
      Object.entries(msg).forEach(error => {
        message += error[1]
      })
      this.showNotification(type, message)
    },
    showNotification (color, text) {
      this.snackbar = true
      this.notificationType = color
      this.notificationText = text
    }
  },
  mounted () {
    this.geolocate()
  },
  created () {
    planner.listTransportations().then(response => {
      this.transportations = response.data.data
    }).catch(() => {
    })
    planner.listStays().then(response => {
      this.stays = response.data.data
    }).catch(() => {
    })
  }
}
</script>
