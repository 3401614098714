<template>
    <div>
        <v-snackbar class="white--text text-center" top v-model="snackbar" :timeout="3000" :color="notificationType">{{notificationText}}</v-snackbar>
        <v-container class="plan-basic">
          <v-form v-model="valid" ref="tripPost">
            <postTrip :readonly="showStops" @setTitle="setTitle" @setDesc="setDesc" />
            <v-row v-if="!showStops" no-gutters justify="center" class="mt-5">
              <v-btn @click="$router.push({ name: 'plansList' })" class="mx-5" color="white primary--text" x-large>back to trips</v-btn>
              <v-btn :loading="loading" @click="showStopsInfo" class="mx-5 px-15" color="primary white--text" x-large elevation="0">next</v-btn>
            </v-row>
          </v-form>
        </v-container>

        <!-- stops -->
        <div style="transform: translateY(-20%)" v-if="showStops">
          <v-container fluid class="px-10">
            <stops :id="planId" />
          </v-container>
        </div>
    </div>
</template>

<script>
import postTrip from '@/components/plan_trip/post.vue'
import stops from '@/components/plan_trip/stopsController.vue'
import planner from '../../web_services/planner'

export default {
  components: { postTrip, stops },
  data () {
    return {
      snackbar: false,
      notificationType: '',
      notificationText: '',
      planBasicInfo: {
        name: '',
        description: ''
      },
      valid: true,
      showStops: false,
      planId: null,
      loading: false
    }
  },
  methods: {
    setTitle (title) {
      this.planBasicInfo.name = title
    },
    setDesc (desc) {
      this.planBasicInfo.description = desc
    },
    showStopsInfo () {
      this.$refs.tripPost.validate()
      if (this.valid) {
        this.loading = true
        planner.addPlanBasicInfo(this.planBasicInfo).then(response => {
          this.showNotification('success', response.data.message)
          this.planId = response.data.data.id
          this.showStops = true
        }).catch(err => {
          this.showNotification('error', err.response.data.message)
        }).finally(() => { this.loading = false })
      } else {
        this.showNotification('warning', 'Please fill all fields')
      }
    },
    showNotification (color, text) {
      this.snackbar = true
      this.notificationType = color
      this.notificationText = text
    }
  }
}
</script>

<style>
.plan-basic {
  transform: translateY(-30%);
}
</style>
